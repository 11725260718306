<script>
import Catalogue from '@/services/catalogue';
import Multiselect from 'vue-multiselect'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';


export default {
    components: {
        Multiselect,
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            momentFormat: {
                stringify: (date) => {
                    return date ? this.moment(date).format('dddd') + ' ' + this.moment(date).format('LL') : ''
                },
                parse: (value) => {
                    console.log(value);
                    return value ? this.moment(value, 'DD-MM-YYYY').toDate() : ''
                }
            },
            search: {
                type_room_id: 1,
                date: '',
                time_start: '',
                time_end: '',
                assistants: '',
                territory_id: '',
                region_id: ''
            },
            territory: '',
            region: '',
            num_assistants: [],
            state: {
                disabledDates: {
                    to: new Date(),
                }
            },
            // MIN -  MAX
            min_assistants: 0,
            max_assistants: 0,
            min_hours: 0,
            max_hours: 0,
            load: false
        }
    },
    methods: {
        disabledRange: function (date) {
            return date <= this.moment().subtract(1, 'd');
        },
        async loopAssistants() {
            if (this.catalogue.min_max_assistants) {
                let n = [];
                let min = this.catalogue.min_max_assistants[0].data.min_assistants;
                let max = this.catalogue.min_max_assistants[0].data.max_assistants;

                for (let index = min; index <= max; index++) {
                    n.push(index);
                }

                this.num_assistants = n;
            }

        },
        limitTextTerritorio(count) {
            return `y ${count} otros territorios`
        },
        asyncFindTerritorio(query) {
            try {
                this.catalogue.get_territories(query);
            } catch (error) {
                console.log(error);
            }
        },
        limitTextRegion(count) {
            return `y ${count} otros Regiones`
        },
        asyncFindRegion(query = '') {
            try {
                let id = this.territory.id;
                this.catalogue.get_regions(query, id);
            } catch (error) {
                console.log(error);
            }
        },
        setTypeRoom(value) {
            console.log(value);
            this.search.type_room_id = value;
        },
        sendForm() {
            let search = this.search;
            localStorage.setItem('filter_data', JSON.stringify(search));
            this.$router.push({ name: 'list' })
        },
        loadSerach() {
            this.load = true;
            this.search.region_id = '';
            let vm = this;
            setTimeout(function () {
                vm.load = false;
            }, 900);
        },
    },
    watch: {
        territory(newValue) {
            if (newValue) {
                this.asyncFindRegion();
                this.region = '';
                this.search.territory_id = newValue.id;
            }
        },
        region(newValue) {
            if (newValue) {
                this.search.region_id = newValue.id;
            } else {
                this.search.region_id = '';
            }
        },
        'search.assistants'(newValue) {
            let max = this.catalogue.min_max_assistants[0].data.max_assistants;
            if (max < newValue) {
                this.search.assistants = max;
            }
        },
        'search.type_room_id': function () {
            this.loadSerach();
        }
    },
    async mounted() {
        await this.catalogue.get_type_rooms();
        await this.catalogue.get_min_max_assistants();
        await this.catalogue.get_min_max_hours();
        await this.catalogue.get_territories();
        await this.catalogue.get_regions();
        await this.loopAssistants();

        this.min_assistants = this.catalogue.min_max_assistants[0].data.min_assistants;
        this.max_assistants = this.catalogue.min_max_assistants[0].data.max_assistants;
        this.min_hours = this.catalogue.min_max_hours[0].data.min_time;
        this.max_hours = this.catalogue.min_max_hours[0].data.max_time;
    }
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-pills nav-tabs" id="custom-content-below-tab" role="tablist">
                <li class="nav-item" v-for="(type, index) in catalogue.type_rooms.data" :key="index">
                    <a class="nav-link" data-toggle="pill" :aria-selected="type.id == search.type_room_id ? true : false"
                        href="#" role="tab" :class="type.id == search.type_room_id ? 'active' : ''"
                        @click="setTypeRoom(type.id)">{{ type.name }}</a>
                </li>

            </ul>
        </div>

        <div class="col-12">
            <div class="card card-primary card-tabs" style="border-radius: 20px 20px 20px 20px; margin-top: -13px;">
                <div class="card-body" v-if="load">
                    <div class="row">
                        <div class="col-12 text-center p-5">
                            <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                            <h4 class="mt-3">Cargando</h4>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <label class="form-label">¿Qué día necesitas reservar tu espacio?</label>
                    <br>
                    <!-- <date-picker placeholder="Buscar por fecha" v-model="search.date" valueType="format"
                        :disabled-date="disabledRange" input-class="form-control" format="DD-MM-YYYY"></date-picker> -->
                    <date-picker placeholder="Buscar por fecha" v-model="search.date" :formatter="momentFormat"
                        :disabled-date="disabledRange" input-class="form-control"></date-picker>
                    <div class="col-12 mt-3">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <label class="form-label">Hora de entrada</label>
                                <date-picker v-if="!catalogue.min_max_hours_load" v-model="search.time_start"
                                    input-class="form-control"
                                    :time-picker-options="{ start: min_hours, step: '00:30', end: max_hours }"
                                    format="hh:mm" :value-type="'hh:mm'" type="time"></date-picker>
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="form-label">Hora de salida</label>
                                <date-picker v-if="!catalogue.min_max_hours_load" v-model="search.time_end"
                                    input-class="form-control"
                                    :time-picker-options="{ start: min_hours, step: '00:30', end: max_hours }"
                                    format="hh:mm" :value-type="'hh:mm'" type="time"></date-picker>
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="form-label">No. de asistentes</label>
                                <input v-if="!catalogue.min_max_assistants_load" type="number" class="form-control"
                                    v-model="search.assistants" :min="min_assistants" :max="max_assistants">
                                <div class="row text-center" v-else>
                                    <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x"
                                        style="margin: 0 auto;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 mt-3" :class="search.type_room_id == 1 ? 'col-md-6': 'col-md-12' ">
                        <label class="form-label">Selecciona tu territorio</label>
                        <multiselect v-if="!catalogue.territories_load" selectLabel="Presiona para seleccionar"
                            selectedLabel="Seleccionado" deselectLabel="Presiona para eliminar" v-model="territory"
                            label="name" value="id" placeholder="Escribe para buscar" open-direction="bottom"
                            :options="catalogue.territories.data" :internal-search="true">
                            <span slot="noResult">No se encontraron territorios con este nombre.</span>
                        </multiselect>
                        <div class="row text-center" v-else>
                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x" style="margin: 0 auto;"></i>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-3" v-if="search.type_room_id == 1">
                        <label class="form-label">Selecciona tu región</label>
                        <multiselect v-if="!catalogue.regions_load" selectLabel="Presiona para seleccionar"
                            selectedLabel="Seleccionado" deselectLabel="Presiona para eliminar" v-model="region"
                            label="name" value="id" placeholder="Escribe para buscar" open-direction="bottom"
                            :options="catalogue.regions.data" :internal-search="true">
                            <span slot="noResult">No se encontraron regiones con este nombre.</span>
                            <span slot="noOptions">Sin regiones relacionadas.</span>
                        </multiselect>
                        <div class="row text-center" v-else>
                            <i class="fa-solid fa-circle-notch text-secondary fa-spin fa-2x" style="margin: 0 auto;"></i>
                        </div>
                    </div>
                        </div>
                    </div>
                   
                    <div class="col-12 mt-4">
                        <div class="row justify-content-end">
                            <div class="col-md-4 col-12">
                                <button @click="sendForm()" class="btn btn-block rounded-pill btn-danger shadow_btn">Buscar
                                    sala</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style scoped>
.card {
    border-radius: 10px;
}

.shad {
    box-shadow: rgba(0, 0, 0, 0.35) -2px 4px 11px 0px;
    ;
}

hr {
    border-top: 3px solid rgb(133 136 144);
}

.btn-group.btn-group-toggle.w-100.rounded-pill {
    background-color: #7f8081;
}

.btn-secondary {
    background-color: #7f8081;
    border: #7f8081;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #fff;
    border: 1px solid #7f8081;
    border-radius: 30px !important;
    color: #000;
}

.nav-tabs .nav-link.active{
    background-color: #dc3545 !important;
}

@media (max-width: 450px) {

    .text-repo {
        display: block;
        margin-top: 10px;
        text-align: center;
    }
}

div.btn-group-toggle>label:first-child {
    border-radius: 30px 0px 0px 30px;
}

div.btn-group-toggle>label:last-child {
    border-radius: 0px 30px 30px 0px;
}

.nav-tabs .nav-link {
    background: #fff;
    color: #000; 
}

.nav-tabs {
    border-bottom: 0px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.nav-link{
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    border-radius: 20px 20px 0px 0px !important;
}
</style>