<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import request from '../services/request'

export default {
    data() {
        return {
            form: {
                first_name: '   ',
                last_name: '',
                email: '',
                code_user: '',
                phone: '',
                birthday: '',
                gender: '',
                notification: '',
                email_for_notifications: '',
            },
            valid_mail: false,
            errors: {},
            submitted: false
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user'
        })
    },
    methods: {
        async updateUpdate() {
            try {
                let _endpoint = '/update_profile';
                let service = request;
                let reserve = this.form;

                let { data } = await service.post(_endpoint, reserve)
                if (data) {
                    await this.successNotification('Éxito al guardar la información.');
                    await this.setUser(data.user);
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async getData() {
            try {
                let _endpoint = '/user';
                let service = request;

                let { data } = await service.get(_endpoint)
                if (data) {
                    this.form.first_name = data.first_name;
                    this.form.last_name = data.last_name;
                    this.form.email = data.email;
                    this.form.code_user = data.code_user;
                    this.form.phone = data.phone;
                    this.form.birthday = data.birthday;
                    this.form.email_for_notifications = data.email_for_notifications;
                    this.form.notification = data.notification;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async validProfile() {
            try {
                let _endpoint = '/verify_profile';
                let service = request;

                let { data } = await service.get(_endpoint)
                if (data) {
                    if (data == true) {
                        this.$swal({
                            icon: 'info',
                            title: 'Completa los datos de perfil',
                            html: 'Correo para notificaciones y Número de teléfono',
                            confirmButtonColor: '#ff0400',
                        });
                    }
                    console.log(data);
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        ...mapMutations({
            setUser: 'auth/setUser'
        }),
    },
    created() {
        this.validProfile();
        this.getData();
    },
}
</script>
<template>
    <form @submit.prevent="updateUpdate">
        <div class="row">
            <div class="col-12">
                <h2>Datos personales</h2>
                <h4 class="text-muted">Actualiza tus datos personales</h4>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Nombres:
                    </div>
                    <div class="col-8">
                        <input class="form-control" required v-model="form.first_name">
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Apellidos:
                    </div>
                    <div class="col-8">
                        <input class="form-control" required v-model="form.last_name">
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Dirección de correo:
                    </div>
                    <div class="col-8">
                        <input class="form-control" readonly v-model="form.email">
                        <span style="font-size: 11px; line-height: 5px;">Este es el correo que usas para
                            iniciar sesión.</span>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Notificaciones:
                    </div>
                    <div class="col-8 text-center">
                        <label>
                            <input type="checkbox" class="form-control form-control-sm noti" :value="1"
                                v-model="form.notification">
                            <span style="font-size: 11px; line-height: 5px;">Activar para recibir notificaciones</span>
                        </label>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Correo para notificaciones:
                    </div>
                    <div class="col-8">
                        <input class="form-control" v-model="form.email_for_notifications">
                        <span style="font-size: 11px; line-height: 5px;">Este es el correo que usas para recibir
                            la confirmación de las reservas y otras notidficaciones.</span>
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Número de teléfono:
                    </div>
                    <div class="col-8">
                        <input class="form-control" v-model="form.phone">
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Fecha de nacimiento:
                    </div>
                    <div class="col-8">
                        <input type="date" v-model="form.birthday" class="form-control">
                    </div>
                </div>
                <hr>
            </div>
            <!-- <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Género:
                    </div>
                    <div class="col-8">
                        <input class="form-control" v-model="form.gender">
                    </div>
                </div>
                <hr>
            </div> -->
            <div class="col-12">
                <div class="row p-2">
                    <div class="col-4">
                        Número de empleado:
                    </div>
                    <div class="col-8">
                        <input class="form-control" required v-model="form.code_user">
                    </div>
                </div>
                <hr>
            </div>
            <div class="col-12 text-right">
                <button type="submit" class="btn btn-danger br20 shaw20 pl-4 pr-4">
                    <i class="fa-solid fa-floppy-disk"></i>
                    Guardar
                </button>
            </div>
        </div>
    </form>
</template>
