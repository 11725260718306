<script>
export default {
    props: ['media'],
    data() {
        return {
            baseFiles: this.$BaseUrlFiles,
        }
    }
}
</script>
<template>
    <div class="row">
        <div v-if="media.length == 0" class="col-12">
            <img src="@/assets/img/office/1.jpg"
                class="card-img-top roun_top_left roun_top_right roun_bot_left roun_bot_right" alt="photo"
                style="max-height: 440px;">
        </div>
        <!-- uno -->
        <div v-if="media.length == 1" class="col-12">
            <img :src="`${baseFiles}${media[0].media.file_path}${media[0].media.file_name}`"
                class="card-img-top roun_top_left roun_top_right roun_bot_left roun_bot_right" alt="photo"
                style="max-height: 440px;">
        </div>
        <!-- dos -->
        <div v-if="media.length == 2" class="col-12">
            <div class="row">
                <div class="col-6">
                    <img :src="`${baseFiles}${media[0].media.file_path}${media[0].media.file_name}`"
                        class="card-img-top roun_top_left roun_bot_left" alt="photo" style="height: 300px;">
                </div>
                <div class="col-6">
                    <img :src="`${baseFiles}${media[1].media.file_path}${media[1].media.file_name}`"
                        class="card-img-top roun_top_right roun_bot_right" alt="photo" style="height: 300px;">
                </div>
            </div>
        </div>
        <!-- tres -->
        <div v-if="media.length == 3" class="col-12">
            <div class="row">
                <div class="col-4">
                    <img :src="`${baseFiles}${media[0].media.file_path}${media[0].media.file_name}`"
                        class="card-img-top roun_top_left roun_bot_left" alt="photo" style="min-height: 244px; max-height: 300px;">
                </div>
                <div class="col-4">
                    <img :src="`${baseFiles}${media[1].media.file_path}${media[1].media.file_name}`" class="card-img-top"
                        alt="photo" style="min-height: 244px; max-height: 300px;">
                </div>
                <div class="col-4">
                    <img :src="`${baseFiles}${media[2].media.file_path}${media[2].media.file_name}`"
                        class="card-img-top roun_top_right roun_bot_right" alt="photo" style="min-height: 244px; max-height: 300px;">
                </div>
            </div>
        </div>
        <!-- cuadro -->
        <div v-if="media.length == 4" class="col-12">
            <div class="row">
                <div class="col-6">
                    <img :src="`${baseFiles}${media[0].media.file_path}${media[0].media.file_name}`"
                        class="card-img-top m-1 roun_top_left " alt="photo" style="height: 300px;">
                </div>
                <div class="col-6">
                    <img :src="`${baseFiles}${media[1].media.file_path}${media[1].media.file_name}`"
                        class="card-img-top m-1 roun_top_right" alt="photo" style="height: 300px;">
                </div>
                <div class="col-6">
                    <img :src="`${baseFiles}${media[2].media.file_path}${media[2].media.file_name}`"
                        class="card-img-top m-1  roun_bot_left" alt="photo" style="height: 300px;">
                </div>
                <div class="col-6">
                    <img :src="`${baseFiles}${media[3].media.file_path}${media[3].media.file_name}`"
                        class="card-img-top m-1 roun_bot_right" alt="photo" style="height: 300px;">
                </div>
            </div>
        </div>
        <!-- cinco -->
        <div v-if="media.length == 5" class="col-12">
            <div class="row">
                <div class="col-6">
                    <img :src="`${baseFiles}${media[0].media.file_path}${media[0].media.file_name}`"
                        class="card-img-top m-1 roun_top_left roun_bot_left" alt="photo" style="height: 300px;">
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-6">
                            <img :src="`${baseFiles}${media[1].media.file_path}${media[1].media.file_name}`"
                                class="card-img-top m-1 " alt="photo" style="height: 300px;">
                        </div>
                        <div class="col-6">
                            <img :src="`${baseFiles}${media[2].media.file_path}${media[2].media.file_name}`"
                                class="card-img-top m-1 roun_top_right" alt="photo" style="height: 300px;">
                        </div>
                        <div class="col-6">
                            <img :src="`${baseFiles}${media[3].media.file_path}${media[3].media.file_name}`"
                                class="card-img-top m-1 " alt="photo" style="height: 300px;">
                        </div>
                        <div class="col-6">
                            <img :src="`${baseFiles}${media[4].media.file_path}${media[4].media.file_name}`"
                                class="card-img-top m-1 roun_bot_right" alt="photo" style="height: 300px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.roun_top_left {
    border-top-left-radius: 20px !important;
}

.roun_top_right {
    border-top-right-radius: 20px !important;
}

.roun_bot_left {
    border-bottom-left-radius: 20px !important;
}

.roun_bot_right {
    border-bottom-right-radius: 20px !important;
}
</style>