<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';
import request from '../services/request'
import ModalMapComponent from '@/components/ModalMapComponent.vue';

export default {
    components: {
        ModalMapComponent
    },
    data() {
        return {
            catalogue: new Catalogue(),
            baseFiles: this.$BaseUrlFiles,
            territory: '',
            region: '',
            lists: [],
            listsPass: [],
            favorites: [],
            errors: {},
            currentDate: new Date(),
        }
    },
    methods: {
        showMap(office) {
            console.log(office);
            Event.$emit('event-map-office', office);
        },
        async list() {
            let service = request;
            let _endpoint = '/booking/list?status=1';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.lists = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async listPass() {
            let service = request;
            let _endpoint = '/booking/list?status=2';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.listsPass = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        cancelReservation(id) {
            let service = request;
            let vm = this;
            let _endpoint = `/booking/status/${id}?status=0`;
            this.$swal({
                title: 'Cancelar reservación',
                text: 'Se cancelara y estara disponible para otros usuario',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let { data } = await service.get(_endpoint)
                        if (data) {
                            this.errors = await this.successNotification('Éxito al cancelar la reservación')
                            vm.list();
                        }
                    } catch (error) {
                        this.errors = await this.errorNotification(error)
                    }
                }
            })
        },
        async save_favorite(id) {
            try {
                let form = {
                    id: id
                };
                let _endpoint = '/favorite/save';
                let service = request;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.get_favorite();
                    if (data.type == 'deleted') {
                        this.errors = await this.successNotification('Éxito al eliminar de tus favoritos')
                    } else {
                        this.errors = await this.successNotification('Éxito al agregar a favoritos');
                    }
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async get_favorite() {
            try {
                let _endpoint = '/favorite/list';
                let service = request;
                let { data } = await service.get(_endpoint)
                if (data) {
                    let ids = data.map(x => x['room_id']);
                    this.favorites = ids;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        exist_favorite(id) {
            console.log(id);
            console.log(this.favorites);
            let result = this.favorites.includes(id);     // true
            console.log(result);
            return result;
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
    },
    mounted() {
        this.setHeaderTitle('Salas reservada');
        this.list();
        this.listPass();
        if (this.authUser) {
            this.get_favorite();
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
}
</script>
<template>
    <div class="container pt-4">
        <div class="row">
            <ModalMapComponent />
            <div class="col-12 mt-3">
                <div class="card  br20 m-2 shaw20 mb-4">
                    <div class="card-body">
                        <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-reserves-tab" data-toggle="pill" href="#pills-reserves"
                                    role="tab" aria-controls="pills-reserves" aria-selected="true">Activas</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-reserves-old-tab" data-toggle="pill"
                                    href="#pills-reserves-old" role="tab" aria-controls="pills-reserves-old"
                                    aria-selected="false">Pasados</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-reserves" role="tabpanel"
                        aria-labelledby="pills-reserves-tab">
                        <div class="row" v-if="lists.data.length > 0">
                            <div v-for="(like, index_like) in lists.data" :key="index_like"                            
                                class="col-md-6 col-12">
                                <div class="card crd-sh br20 shaw20">
                                    <i v-if="authUser" class="fa-lg fa-solid fa-heart btn-like"
                                        @click="save_favorite(like.room.id)"
                                        :class="exist_favorite(like.room.id) ? 'text-danger' : 'text-muted'"></i>
                                    <img v-if="like.room.medias.length > 0"
                                        :src="`${baseFiles}${like.room.medias[0].media.file_path}${like.room.medias[0].media.file_name}`"
                                        class="card-img-top rounded-top" alt="photo">
                                    <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top"
                                        alt="photo">
                                    <div class="card-body card-info-room">
                                        <div class="div_star">
                                            {{ like.room.star }}
                                            <i class="fa-solid fa-star"
                                                :class="like.room.star > 0 ? 'text-warning' : 'text-muted'"></i>
                                        </div>
                                        <h4 role="button" @click="redirectOffice(like.id)" class="card-title tit">{{ like.room.name }}</h4>
                                        <p class="card-text ml-n2 mt-4 card_address text-muted col-10"
                                            style="font-size: 12px;">
                                            <a @click="showMap(like.room)" class="text-muted">
                                                {{ like.room.address }}
                                            </a>
                                        </p>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <h5 class="text-success">Reservado</h5>
                                                <p style="font-size: 13px;" class="mt-n1 mb-1">Fecha: {{
                                                    moment(like.date).format('DD/MM/YYYY') }}</p><br>
                                                <p style="font-size: 13px;" class="mt-n4">Horario: {{
                                                    like.time_start.substring(0, 5) }}
                                                    Hrs a {{ like.time_end.substring(0, 5) }} Hrs</p>
                                                <router-link :to="{ path: 'office/' + like.room.id }"
                                                    class="btn btn-danger rounded-r mt-n4">Ver más</router-link>
                                                <button @click="cancelReservation(like.id)"
                                                    class="btn btn-secondary mt-3 rounded-r ">Cancela</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center">
                            <div class="col-7 text-center">
                                <div class="card br20 shaw20">
                                    <div class="card-body p-5">
                                        <i class="fa-solid fa-magnifying-glass-location fa-5x text-danger"></i>
                                        <H3 class="mt-3">Sin reservas existentes</H3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-reserves-old" role="tabpanel"
                        aria-labelledby="pills-reserves-old-tab">
                        <div class="row" v-if="listsPass.data.length > 0">
                            <div v-for="(like, index_like) in listsPass.data" :key="index_like" class="col-md-6 col-12"
                                :class="currentDate < new Date(`${like.date}T${like.time_end}`) ? 'd-none' : ''">
                                <div class="card br20 shaw20">
                                    <i v-if="authUser" class="fa-lg fa-solid fa-heart btn-like"
                                        @click="save_favorite(like.room.id)"
                                        :class="exist_favorite(like.room.id) ? 'text-danger' : 'text-muted'"></i>
                                    <img v-if="like.room.medias.length > 0"
                                        :src="`${baseFiles}${like.room.medias[0].media.file_path}${like.room.medias[0].media.file_name}`"
                                        class="card-img-top rounded-top" alt="photo">
                                    <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top"
                                        alt="photo">
                                    <div class="card-body card-info-room">
                                        <div class="div_star">
                                            {{ like.room.star }}
                                            <i class="fa-solid fa-star"
                                                :class="like.room.star > 0 ? 'text-warning' : 'text-muted'"></i>
                                        </div>
                                        <h4 role="button" @click="redirectOffice(like.id)" class="card-title tit">{{ like.room.name }}</h4>
                                        <p class="card-text ml-n2 mt-4 card_address text-muted col-10"
                                            style="font-size: 12px;">
                                            <a @click="showMap(like.room)" class="text-muted">
                                                {{ like.room.address }}
                                            </a>
                                        </p>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <h5 class="text-success">Reservado</h5>
                                                <p style="font-size: 13px;" class="mt-n1 mb-1">Fecha: {{
                                                    moment(like.date).format('DD/MM/YYYY') }}</p><br>
                                                <p style="font-size: 13px;" class="mt-n4">Horario: {{
                                                    like.time_start.substring(0, 5) }}
                                                    Hrs a {{ like.time_end.substring(0, 5) }} Hrs</p>
                                                <router-link :to="{ path: 'reviews/' + like.id }"
                                                    class="btn btn-danger rounded-r mt-n1">Opinar</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center">
                            <div class="col-7 text-center">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <i class="fa-solid fa-magnifying-glass-location fa-5x text-danger"></i>
                                        <H3 class="mt-3">Sin reservas existentes</H3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12">
                <div class="row mt-4 pb-4">

                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.shw {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.crd-sh {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.div_icons {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 110px;
}

@media (max-width: 546px) {
    .txt-h {
        display: none;
    }

    .icon_res {
        font-size: 20px;
    }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #636666;
    border-radius: 30px;
}</style>