<template>
	<div class="full_load">
		<div class="row align-items-center justify-content-center h-100">
			<div class="col-12 text-center">
				<!-- <img src="@/assets/img/logo_sin_letras.png" class="img-fluid mt-5 loading-spinner" alt="elektra"
					style="max-width: 170px; margin-bottom: 50px;"> -->
				<!-- <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader> -->
				<!-- <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader> -->
				<scale-loader :loading="loading" :color="color" :height="'100px'" :width="'20px'"></scale-loader>
			</div>
		</div>
	</div>
</template>
<script>
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
// import GridLoader from 'vue-spinner/src/GridLoader.vue'
// import BeatLoader from 'vue-spinner/src/GridLoader.vue'
// import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
	components: {
		// PulseLoader,
		// GridLoader,
		// BeatLoader,
		// BounceLoader
		ScaleLoader
	},
	data() {
		return {
			loading: true,
			color: '#DA291C',
			size: '599px',
		}
	},
}
</script>
<style scoped>
.full_load {
	background-color: #4a4a4a75;
	/* background-color: #065e4cf5; */
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 1087;
	backdrop-filter: blur(4px);
}

.loading-spinner {
  animation: rotate 2.5s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

</style>
