import Vue from 'vue'

// import axios  from 'axios'

import App from './App.vue'
import router from './router'
import store from './store'

require('bootstrap')
require('@/assets/css/adminlte.min.css');
require('@/assets/css/custom.css');
require('@/assets/css/hint.min.css');

import { authHeader } from './helpers';
authHeader()

// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   if(error.response.status == 401) {
//       // localStorage.clear()
//       sessionStorage.clear()
//       // location.reload()
//   }
// });

import vmodal from 'vue-js-modal'
// import 'vue-js-modal/dist/styles.css'
Vue.use(vmodal);
Vue.use(localStorage)

import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 6000
  }
}
Vue.use(Snotify, options)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCNh1zGzQyZNuVHCc2uFwdKOLqDKDgiI4g',
    libraries: 'places',
    region: 'MX',
    language: 'es-419',
  },
  autobindAllEvents: false,
  installComponents: true
})

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);


import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');
var meses = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_');
var semanas = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_');
moment.updateLocale('es', { months : meses, weekdays : semanas });
Vue.prototype.moment = moment

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

Vue.config.productionTip = false

// Vue.prototype.$BaseUrlFiles = window.location.hostname == 'localhost' ? 'http://127.0.0.1:8000' : 'https://admin-reservas.sorianoariza.com';
Vue.prototype.$BaseUrlFiles = 'https://admin-reservas.sorianoariza.com';

window.Event = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
