<script>
import { mapActions, mapMutations } from 'vuex';
import request from '@/services/request'

export default {
	props: {
	},
	data() {
		return {
			form: {
				email: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerResetPassword() {
			let _endpoint = '/auth/reset-password';
			try {
				let reset = await request.post(_endpoint, this.form)
				if (reset) {
					this.successNotification('Se ha enviado una nueva contraseña a tu correo');
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
	},
	mounted() {
		this.setHeaderTitle(`Recuperar contraseña`);
	}
};
</script>

<template>
	<div class="row">
		<div class="lockscreen-wrapper">
			<div class="col-12 text-center mt-5">
				<h1 class="text-danger">Recuperar contraseña</h1>
				<p class="text-muted">Ingresa el correo con el que estás registrado</p>
			</div>
			<form @submit.prevent="triggerResetPassword">
				<div class="col-12 mb-3">
					<div class="input-group ">
						<input type="email" required v-model="form.email" name="email" class="form-control" placeholder="Correo"
							:class="{ 'is-invalid': submitted && !form.email }" />
					</div>
					<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
				</div>
				<div class="col-12 mb-3">
					<div class="form-group text-center">
						<button class="btn col-9 btn-danger br20 shaw20" :disabled="submitted">Restablecer
							contraseña <i class="fa fa-paper-plane float-right mt-1" aria-hidden="true"></i></button>
					</div>
				</div>
				<div class="col-md-12 text-center">
					<router-link class="btn btn-link text-danger" to="/login" href="">Iniciar sesión</router-link>
				</div>
			</form>
		</div>
	</div>
</template>

