<script>
import { mapActions, mapMutations } from 'vuex';
import carousel from 'vue-owl-carousel'
import request from '../services/request'

import galeryOffice from '@/components/GaleryOfficeComponent.vue'
import StarRating from 'vue-star-rating'



export default {
    components: {
        carousel,
        galeryOffice,
        StarRating
    },
    data() {
        return {
            office: {},
            comment: {
                id: '',
                comment: '',
                score: 0,
                type_comments: 1,
                room_id: '',
                booking_id: '',
            },
            comment2: '',
            error: {
                score: false
            },
            timesStars: '',
            timesEnd: '',
            baseFiles: this.$BaseUrlFiles,
            send: false,
        }
    },
    methods: {
        async get_office() {
            let service = request;
            let _endpoint = `/booking/list/${this.id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.office = data.data.room;
                    this.comment.room_id = this.office.id;
                    console.log(data.data.comment);
                    if (data.data.comment.length > 0) {
                        this.comment = data.data.comment[0];
                        this.comment2 = data.data.comment[1].comment;
                    }
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async sendComment() {

            if (this.comment.score == 0) {
                this.$swal({
                    title: 'La calificación es requerida',
                    text: 'ingresar una calificacion para continuar.',
                    icon: 'warning',
                });
                return false;
            }
            this.send = true;

            this.$swal({
                title: 'Enviar comentarios',
                text: 'No podrás editar el comentario',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result) {
                        try {
                            let service = request;
                            let _endpoint = '/booking/comment'
                            let form = this.comment;
                            let { data } = await service.post(_endpoint, form)
                            if (data) {
                                if (this.comment2 != '') {
                                    this.saveComentPrivad();
                                } else {
                                    this.send = false;
                                    await this.successNotification('Éxito al guardar el comentario.');
                                    this.$router.push({ name: 'bookings' });
                                }

                            }
                        } catch (error) {
                            this.send = false;
                            this.errors = await this.errorNotification(error)
                        }
                    }
                } else {
                    this.send = false;
                }
            });
        },
        async saveComentPrivad() {
            try {
                let service = request;
                let _endpoint = '/booking/comment';
                let form = this.comment;
                form.comment = this.comment2;
                form.type_comments = 2;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.send = false;
                    await this.successNotification('Éxito al guardar el comentario.');
                    this.$router.push({ name: 'bookings' });
                }
            } catch (error) {
                this.send = false;
                this.errors = await this.errorNotification(error)
            }
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.setHeaderTitle(`Opiniones`);
        this.id = this.$route.params.id;
        if (this.id) {
            this.comment.booking_id = this.id
            this.get_office();
        }
    }
}
</script>
<template>
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 mt-5 mb-5">
                <div class="row">
                    <div class="col-12">
                        <h2>{{ office.name }}</h2>
                        <p class="card-text mt-2 mb-4" style="font-size: 16px;">
                            {{ office.address }}
                        </p>

                    </div>
                </div>

                <!-- Cards -->
                <div class="row">
                    <div class="col-md-12">

                        <!-- Cards -->
                        <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                            <div class="row">
                                <div class="col-12">
                                    <galeryOffice :media="office.medias" />
                                </div>
                            </div>
                        </div>

                        <!-- CARROUCEL -->
                        <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                            <div class="row">
                                <div class="col-12">
                                    <carousel :autoplay="true" v-if="office.medias" :nav="true" :loop="true" :items="1">
                                        <img v-for="(media, index_media) in office.medias" :key="index_media"
                                            :src="`${baseFiles}${media.media.file_path}${media.media.file_name}`">
                                    </carousel>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12">
                        <div classs="row">
                            <div class="col-12 mt-4 mb-4 text-center">
                                <hr>
                            </div>
                            <form @submit.prevent="sendComment">

                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <h4>Gracias por contribuir con la comunidad</h4>
                                            <p> Día a día, miles de personas leen opiniones antes de decidir su reserva.</p>
                                        </div>
                                        <div class="col-12">
                                            <h5>Calificación:</h5>
                                            <star-rating :read-only="comment.id != ''" v-model="comment.score" />
                                            <span v-if="error.score">Es requerida la calificación</span>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <h5>Comentarios</h5>
                                            <textarea rows="4" :readonly="comment.id != ''" v-model="comment.comment"
                                                class="form-control"></textarea>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <h5>Comentario Privado</h5>
                                            <textarea rows="4" :readonly="comment.id != ''" v-model="comment2"
                                                class="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <router-link :to="{ path: '/bookings' }"
                                        class="btn btn-lg btn-secondary float-left pl-5 pr-5" style="border-radius: 30px;">
                                        Regresar </router-link>
                                    <button v-if="comment.id == ''" :disabled="send"
                                        class="btn btn-lg btn-danger float-right pl-5 pr-5" style="border-radius: 30px;">
                                        <i v-if="send" class="fa-solid fa-circle-notch fa-spin mr-2"></i>
                                        <span v-if="send">Enviando</span>
                                        <span v-else>Enviar comentarios</span>
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 150px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.hr_head {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 42px;
    position: relative;
}

.hr_footer {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 282px;
    position: relative;
}

.shadow_cal {
    box-shadow: rgba(136, 165, 191, 0.48) 5px 6px 6px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.swal2-icon.swal2-success.swal2-icon-show {
    margin-top: 120px !important;
}

.img.swal2-image {
    position: absolute;
    left: 32% !important;
}
</style>
