<script>
import { mapActions, mapMutations } from 'vuex';
import carousel from 'vue-owl-carousel'
import request from '../services/request'

import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale'
import moment from 'moment';

export default {
    components: {
        carousel,
        Datepicker
    },
    data() {
        return {
            es: es,
            office: {},
            reserve: {
                date: '',
                room_id: '',
                participants: '',
                time_start: '',
                time_end: ''
            },
            timesStars: '',
            timesEnd: '',
            baseFiles: this.$BaseUrlFiles,
            send: false,
        }
    },
    methods: {
        async get_office() {
            let service = request;
            let _endpoint = `/booking/rooms/${this.id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.office = data.data;
                    this.setHeaderTitle(`${this.office.name}`);
                    this.catchParameters();

                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        disbaledDays() {
            let disabledDates = {
                to: new Date(), // Disable all dates up to specific date
                // from: new Date(2016, 0, 26), // Disable all dates after specific date
                days: this.getDays(), // Disable Saturday's and Sunday's              
            }
            return disabledDates;
        },
        loopAssistants() {
            let assistants = this.office.assistants;
            let numbers = [];
            for (let index = 1; index <= assistants; index++) {
                numbers.push(index);
            }
            return numbers;
        },
        getDays() {
            let arrayDays = JSON.parse(this.office.days_available);
            let days = [];
            // 0 - Domingo
            // 6 - Sabado
            let daysWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            daysWeek.forEach(function (day) {
                if (!arrayDays.includes(day)) {
                    if (day == 'sunday') {
                        days.push(0);
                    }
                    if (day == 'monday') {
                        days.push(1);
                    }
                    if (day == 'tuesday') {
                        days.push(2);
                    }
                    if (day == 'wednesday') {
                        days.push(3);
                    }
                    if (day == 'thursday') {
                        days.push(4);
                    }
                    if (day == 'friday') {
                        days.push(5);
                    }
                    if (day == 'saturday') {
                        days.push(6);
                    }
                }
            });
            return days;
        },
        async showTime(data) {
            let dateFormat = moment(data).format('YYYY-MM-DD');
            let service = request;
            let _endpoint = `/booking/hours_bookings/${this.id}?date=${dateFormat}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.timesUseds = data.data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
            await this.getTimes(this.office.time_start, this.office.time_end);
        },
        getTimes(start = '09:00:00', end = '21:00:00', returnA = false) {
            let timesInBetween = [];
            let startH = parseInt(start.split(":")[0]);
            let startM = parseInt(start.split(":")[1]);
            let endH = parseInt(end.split(":")[0]);
            let endM = parseInt(end.split(":")[1]);

            if (startM == 30)
                startH++;
            for (let i = startH; i < endH; i++) {
                timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
                timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
            }
            timesInBetween.push(endH < 10 ? "0" + endH + ":00" : endH + ":00");
            if (endM == 30)
                timesInBetween.push(endH < 10 ? "0" + endH + ":30" : endH + ":30");

            if (returnA) {
                return timesInBetween;
            } else {
                timesInBetween.pop();
                this.timesStars = timesInBetween;
            }
        },
        statusHorario(time) {
            let resul = false;
            let timesUseds = this.timesUseds;
            if (timesUseds) {
                timesUseds.forEach(timesU => {
                    let range = this.getTimes(timesU.time_start, timesU.time_end, true);
                    if (range) {
                        if (range.includes(time)) {
                            resul = true;
                        }
                    }
                });
            }
            return resul;
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        catchRange(time) {
            let hours = this.getTimes(this.office.time_start, this.office.time_end, true);
            let prv = [];
            let brk = false;
            if (hours) {
                hours.forEach(timesU => {
                    if (time < timesU) {
                        if (this.statusHorario(timesU)) {
                            if (!brk) {
                                prv.push(timesU);
                            }
                            brk = true;
                        }
                        if (!brk) {
                            prv.push(timesU);
                        }
                    }
                });
            }
            this.timesEnd = prv;
        },
        confirm_reserve() {
            this.send = true;
            this.$swal({
                html:
                    `<h3>Confirmar reservación:</h3>
                    <div class="row mt-3" style="width:100%">
                        <div class="col-12 col-md-6">
                            <p>Fecha: ${moment(this.reserve.date).format('DD/MM/YYYY')}</p>
                        </div>
                        <div class="col-12 col-md-6">
                            <p>N. asistentes: ${this.reserve.participants}</p>
                        </div>
                        <div class="col-12 col-md-6">
                            <p>Hora entrada: ${this.reserve.time_start}</p>
                        </div>
                        <div class="col-12 col-md-6">
                            <p>Hora salida: ${this.reserve.time_end}</p>
                        </div>
                    </div>`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result) {
                        try {
                            let _endpoint = '/booking/save';
                            let service = request;
                            let reserve = this.reserve;
                            reserve.date = moment(reserve.date).format('YYYY-MM-DD');
                            reserve.time_end = reserve.time_end + ':00';
                            // let dateTime = new moment(reserve.date+' '+reserve.time_end+':00').subtract(1, 'seconds').format('hh:mm:ss');
                            // reserve.time_end = dateTime;
                            reserve.time_start = reserve.time_start + ':01';

                            let { data } = await service.post(_endpoint, reserve)
                            if (data) {
                                this.$swal({
                                    html:
                                        '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
                                        '<img width="180" class="mt-4" src="' + require('@/assets/img/success.png') + '"> <br> ' +
                                        '<h3 class="mt-4">Confirmación exitosa</h3>',
                                    background: '#f9f9f9',
                                    confirmButtonColor: '#da281b',

                                });
                                this.send = false;
                                this.$router.push({ name: 'bookings' });
                            }
                        } catch (error) {
                            this.errors = await this.errorNotification(error)
                            this.send = false;
                        }
                    }
                } else {
                    this.send = false;
                }
            });
            // this.$swal({
            //     html:
            //         '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
            //         '<img width="180" class="mt-4" src="' + require('@/assets/img/success.png') + '"> <br> ' +
            //         '<h3 class="mt-4">Confirmación exitosa</h3>',
            //     background: '#f9f9f9',
            //     confirmButtonColor: '#da281b',

            // }).then(async result => {
            //    
            // });
        },
        async catchParameters() {
            let params = this.$route.query;
            if (params.day) {
                this.reserve.date = params.day;
                this.showTime(params.day);
            }

            if (params.time) {
                this.reserve.time_start = params.time;
                // this.catchRange(params.time);
            }

            console.log(params);
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.setHeaderTitle(`Salas de Experiencia`);
        this.id = this.$route.params.id;
        if (this.id) {
            this.reserve.room_id = this.id;
            this.get_office();
        }
    },
    watch: {
        'reserve.time_start': function (newValue) {
            this.reserve.time_end = '';
            this.catchRange(newValue);
        }
    }
}
</script>
<template>
    <div class="row">
        <div class="col-12 mt-5 mb-5">
            <div class="row">
                <div class="col-8">
                    <h2>{{ office.city }}</h2>
                    <p class="card-text mt-2 mb-4" style="font-size: 16px;">
                        {{ office.address }}
                    </p>

                </div>
            </div>

            <!-- Cards -->
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <carousel :autoplay="true" :nav="true" :loop="true" :items="1" v-if="office.medias">
                                <img class="img-fluid" v-for="(media, index_media) in office.medias" :key="index_media"
                                    :src="`${baseFiles}${media.media.file_path}${media.media.file_name}`">
                            </carousel>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div classs="row">
                        <div class="col-12 text-center">
                            <h2>{{ this.office.name }}</h2>
                            <hr>
                        </div>
                        <form @submit.prevent="confirm_reserve">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 text-center mb-n3">
                                        <p>Selecciona una fecha para listar los horarios disponibles</p>
                                    </div>
                                    <div class="col-6 p-3">
                                        <h4>Fecha:</h4>
                                        <datepicker @selected="showTime" input-class="form-control" :required="true"
                                            v-model="reserve.date" :disabled-dates="disbaledDays()" :language="es">
                                        </datepicker>
                                    </div>
                                    <div class="col-6 p-3">
                                        <h4>No. de asistentes</h4>
                                        <select class="form-control" name="asistent" v-model="reserve.participants" required>
                                            <option :value="n" v-for="(n, index_n) in loopAssistants()" :key="index_n">
                                                {{ n }} - Asistente<span v-if="n > 1">s</span>
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-6 p-3">
                                        <h4>Hora entrada:</h4>
                                        <select class="form-control" required name="time_star" v-model="reserve.time_start">
                                            <option :value="time" :hidden="statusHorario(time)"
                                                v-for="(time, index_time) in timesStars" :key="index_time">
                                                {{ time }} Hrs.
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-6 p-3">
                                        <h4>Hora salida:</h4>
                                        <select class="form-control" required name="time_end" v-model="reserve.time_end">
                                            <option :value="time" v-for="(time, index_time) in timesEnd" :key="index_time">
                                                {{ time }} Hrs.
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <button class="btn btn-danger btn-block" :disabled="send" style="border-radius: 30px;">
                                    <i v-if="send" class="fa-solid fa-circle-notch fa-spin mr-2"></i>
                                    <span v-if="send">Enviando</span>
                                    <span v-else> Confirmar reservación</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h3>Condiciones de tu reserva</h3>
                    <hr style="border-top: 1px solid rgb(0 0 0);">
                    <p style="font-size: 15px;" class="mt-2 mb-1 ">Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit,
                        sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
                        volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
                        nisl ut aliquip ex ea commodo consequat.
                        Duis autem vel eum iriure dolor
                    </p>

                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 150px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.hr_head {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 42px;
    position: relative;
}

.hr_footer {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 282px;
    position: relative;
}

.shadow_cal {
    box-shadow: rgba(136, 165, 191, 0.48) 5px 6px 6px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.swal2-icon.swal2-success.swal2-icon-show {
    margin-top: 120px !important;
}

.img.swal2-image {
    position: absolute;
    left: 32% !important;
}
</style>
