<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import request from '../services/request'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

// import loginModal from '@/components/ModalLoginComponent.vue'

import moment from 'moment';

export default {
    components: {
        DatePicker,
        // loginModal
    },
    data() {
        return {
            office: {},
            reserve: {
                date: '',
                room_id: '',
                participants: '',
                time_start: '',
                time_end: ''
            },
            timesStars: '',
            timesEnd: '',
            baseFiles: this.$BaseUrlFiles,
            send: false,
            momentFormat: {
                stringify: (date) => {
                    return date ? this.moment(date).format('dddd') + ' ' + this.moment(date).format('LL') : ''
                },
                parse: (value) => {
                    console.log(value);
                    return value ? this.moment(value, 'DD-MM-YYYY').toDate() : ''
                }
            }
        }
    },
    methods: {
        disabledRange: function (date) {
            let days = this.getDays();
            let nDay = new Date(date).getDay()
            return date <= moment().subtract(1, 'd') || days.includes(nDay);
        },
        async get_office() {
            let service = request;
            let _endpoint = `/booking/rooms/${this.id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.office = data.data;
                    this.setHeaderTitle(`${this.office.name}`);
                    this.catchParameters();

                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        loopAssistants() {
            let assistants = this.office.assistants;
            let numbers = [];
            for (let index = 1; index <= assistants; index++) {
                numbers.push(index);
            }
            return numbers;
        },
        getDays() {
            let arrayDays = JSON.parse(this.office.days_available);
            let days = [];
            // 0 - Domingo
            // 6 - Sabado
            let daysWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            daysWeek.forEach(function (day) {
                if (!arrayDays.includes(day)) {
                    if (day == 'sunday') {
                        days.push(0);
                    }
                    if (day == 'monday') {
                        days.push(1);
                    }
                    if (day == 'tuesday') {
                        days.push(2);
                    }
                    if (day == 'wednesday') {
                        days.push(3);
                    }
                    if (day == 'thursday') {
                        days.push(4);
                    }
                    if (day == 'friday') {
                        days.push(5);
                    }
                    if (day == 'saturday') {
                        days.push(6);
                    }
                }
            });
            return days;
        },
        async showTime(data) {
            let dateFormat = moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD');
            let service = request;
            this.catNEvent();
            let _endpoint = `/booking/hours_bookings/${this.id}?date=${dateFormat}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.timesUseds = data.data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
            await this.getTimes(this.office.time_start, this.office.time_end);
        },
        getTimes(start = '09:00:00', end = '21:00:00', returnA = false) {
            let timesInBetween = [];
            let startH = parseInt(start.split(":")[0]);
            let startM = parseInt(start.split(":")[1]);
            let endH = parseInt(end.split(":")[0]);
            let endM = parseInt(end.split(":")[1]);

            if (startM == 30)
                startH++;
            for (let i = startH; i < endH; i++) {
                timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
                timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
            }
            timesInBetween.push(endH < 10 ? "0" + endH + ":00" : endH + ":00");
            if (endM == 30)
                timesInBetween.push(endH < 10 ? "0" + endH + ":30" : endH + ":30");

            if (returnA) {
                return timesInBetween;
            } else {
                timesInBetween.pop();
                this.timesStars = timesInBetween;
            }
        },
        statusHorario(time) {
            let resul = false;
            let timesUseds = this.timesUseds;
            if (timesUseds) {
                timesUseds.forEach(timesU => {
                    let range = this.getTimes(timesU.time_start, timesU.time_end, true);
                    if (range) {
                        if (range.includes(time)) {
                            resul = true;
                        }
                    }
                });
            }
            return resul;
        },
        showlogin() {
            console.log('pasoooo1');
            Event.$emit('event-show-login');
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        catchRange(time) {
            let hours = this.getTimes(this.office.time_start, this.office.time_end, true);
            let prv = [];
            let brk = false;
            if (hours) {
                hours.forEach(timesU => {
                    if (time < timesU) {
                        if (this.statusHorario(timesU)) {
                            if (!brk) {
                                prv.push(timesU);
                            }
                            brk = true;
                        }
                        if (!brk) {
                            prv.push(timesU);
                        }
                    }
                });
            }
            this.timesEnd = prv;
        },
        confirm_reserve() {
            if (!this.authUser) {
                return false;
            }

            this.send = true;
            let urlImg = require('@/assets/img/signo.png');
            let dateHuman = this.moment(this.reserve.date).format('dddd') + ' ' + this.moment(this.reserve.date).format('LL');
            this.$swal({
                html:
                    `
                    <img class="img-fluid" src="${urlImg}" style="max-width: 240px;;">
                    <h3>Revisa tus datos:</h3>
                    <hr>
                    <p>Antes de realizar tu reservación es 
necesario que revises y confirmes que tus 
datos con correctos, de ser así da clic en el 
botón de correcto.</p>
                    <hr>
                    <div class="row mt-3" style="width:100%">
                        <div class="col-12 col-md-12">
                            <p><strong class="title_m">Fecha:</strong> <br> ${dateHuman}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><strong class="title_m">N. asistentes:</strong> <br> ${this.reserve.participants}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><strong class="title_m">Hora entrada:</strong> <br> ${this.reserve.time_start}</p>
                        </div>
                        <div class="col-12 col-md-4">
                            <p><strong class="title_m">Hora salida:</strong> <br> ${this.reserve.time_end}</p>
                        </div>
                    </div>`,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#5c5c5c',
                confirmButtonText: 'Confirmar reservación',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result) {
                        try {
                            let _endpoint = '/booking/save';
                            let service = request;
                            let reserve = this.reserve;
                            reserve.date = moment(reserve.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                            reserve.time_end = reserve.time_end + ':00';
                            // let dateTime = new moment(reserve.date+' '+reserve.time_end+':00').subtract(1, 'seconds').format('hh:mm:ss');
                            // reserve.time_end = dateTime;
                            reserve.time_start = reserve.time_start + ':01';

                            let { data } = await service.post(_endpoint, reserve)
                            if (data) {
                                this.$swal({
                                    html:
                                        '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
                                        '<img width="180" class="mt-4" src="' + require('@/assets/img/success.png') + '"> <br> ' +
                                        '<h3 class="mt-4">Confirmación exitosa</h3>',
                                    background: '#f9f9f9',
                                    confirmButtonColor: '#da281b',

                                });
                                this.send = false;
                                localStorage.removeItem('filter_data');
                                localStorage.removeItem('reserve_data');
                                this.$router.push({ name: 'bookings' });
                            }
                        } catch (error) {
                            this.errors = await this.errorNotification(error)
                            this.send = false;
                        }
                    }
                } else {
                    this.send = false;
                }
            });
            // this.$swal({
            //     html:
            //         '<img class="mt-4" width="180" src="' + require('@/assets/img/logo_rojo.png') + '"> <br> ' +
            //         '<img width="180" class="mt-4" src="' + require('@/assets/img/success.png') + '"> <br> ' +
            //         '<h3 class="mt-4">Confirmación exitosa</h3>',
            //     background: '#f9f9f9',
            //     confirmButtonColor: '#da281b',

            // }).then(async result => {
            //    
            // });
        },
        async catchParameters() {
            let params = localStorage.getItem('filter_data');
            if (params !== 'undefined') {
                params = JSON.parse(params);
            }
            let paramsR = localStorage.getItem('filter_data');
            if (paramsR !== 'undefined') {
                paramsR = JSON.parse(localStorage.getItem('reserve_data'));
            }
            if (params && !paramsR) {
                if (params.date) {
                    console.log(params);
                    // this.reserve.date = params.date;
                    this.reserve.date = this.moment(params.date).toDate();
                    this.showTime(params.day);
                }

                if (params.time_start) {
                    this.reserve.time_start = params.time_start;
                    // this.catchRange(params.time);
                }

                if (params.time_end) {
                    this.reserve.time_end = params.time_end;
                    // this.catchRange(params.time);
                }

                if (params.assistants) {
                    this.reserve.participants = params.assistants;
                    // this.catchRange(params.time);
                }
            }
            if (paramsR) {
                if (paramsR.room_id == this.reserve.room_id) {
                    if (paramsR.date) {
                        this.reserve.date = this.moment(paramsR.date).toDate();
                        this.showTime(paramsR.day);
                    }

                    if (paramsR.time_start) {
                        this.reserve.time_start = paramsR.time_start;
                        // this.catchRange(paramsR.time);
                    }

                    if (paramsR.time_end) {
                        this.reserve.time_end = paramsR.time_end;
                    }


                    if (paramsR.participants) {
                        this.reserve.participants = paramsR.participants;
                        // this.catchRange(params.time);
                    }
                } else {
                    localStorage.removeItem('reserve_data');
                }
            }
        },
        catNEvent() {
            let newValue = this.reserve;
            let info = newValue;
            info.office_id = this.reserve.room_id;
            localStorage.setItem('reserve_data', JSON.stringify(newValue));
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        this.setHeaderTitle(`Salas de Experiencia`);
        this.id = this.$route.params.id;
        if (this.id) {
            this.reserve.room_id = this.id;
            await this.get_office();
            await this.catchParameters();
        }
    },
    watch: {
        'reserve.time_start': function (newValue) {
            // this.reserve.time_end = '';
            this.catchRange(newValue);
        }
    },
    created() {
        Event.$on("event-reload-loading", () => {
            this.$modal.hide('ModalLogin');
            console.log('login')
        });

        Event.$on("event-reload-params", async (filter) => {
            if (filter.date != '') {
                this.reserve.date = filter.date;
                let date = moment(filter.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                await this.showTime(date);
            }

            if (filter.participants != '') {
                this.reserve.participants = filter.participants;
            }

            if (filter.time_start != '') {
                this.reserve.time_start = filter.time_start;
                await this.catchRange(filter.time_start);
            }

            if (filter.time_end != '') {
                this.reserve.time_end = filter.time_end;
            }


        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <!-- <loginModal /> -->
            <!-- Cards -->
            <div class="row">
                <div class="col-md-12">
                    <div classs="row">
                        <form @submit.prevent="confirm_reserve">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 mb-n3">
                                        <h3>Información de reserva</h3>
                                        <p>Selecciona una fecha para listar los horarios disponibles</p>
                                        <hr>
                                    </div>
                                    <div class="col-lg-12 col-12 p-2">
                                        <h4>Fecha:</h4>
                                        <!-- <datepicker :format="'dd-MM-yyyy'" @selected="showTime" input-class="form-control"
                                            :required="true" v-model="reserve.date" :disabled-dates="disbaledDays()"
                                            :language="es">
                                        </datepicker> -->
                                        <date-picker @pick="showTime" placeholder="Buscar por fecha" v-model="reserve.date"
                                            :disabled-date="disabledRange" input-class="form-control"
                                            :formatter="momentFormat" :input-attr="{ required: 'true' }"></date-picker>
                                    </div>
                                    <div class="col-lg-12 col-12 p-2">
                                        <h4>No. Asistentes</h4>
                                        <select class="form-control" name="asistent" @change="catNEvent()"
                                            v-model="reserve.participants" required>
                                            <option :value="n" v-for="(n, index_n) in loopAssistants()" :key="index_n">
                                                {{ n }} - Asistente<span v-if="n > 1">s</span>
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-12 p-2">
                                        <h4>Hora entrada:</h4>
                                        <select class="form-control" required name="time_star" v-model="reserve.time_start">
                                            <option :value="time" :hidden="statusHorario(time)"
                                                v-for="(time, index_time) in timesStars" :key="index_time">
                                                {{ time }} Hrs.
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-lg-6 col-12 p-2">
                                        <h4>Hora salida:</h4>
                                        <select class="form-control" required name="time_end" @change="catNEvent()"
                                            v-model="reserve.time_end">
                                            <option :value="time" v-for="(time, index_time) in timesEnd" :key="index_time">
                                                {{ time }} Hrs.
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center pt-3">
                                <button v-if="authUser" class="btn btn-danger br20 shaw20 btn-block" :disabled="send"
                                    style="border-radius: 30px;">
                                    <i v-if="send" class="fa-solid fa-circle-notch fa-spin mr-2"></i>
                                    <span v-if="send">Enviando</span>
                                    <span v-else> Confirmar reservación</span>
                                </button>
                                <button type="button" @click="showlogin()" v-else
                                    class="btn br20 shaw20 btn-danger btn-block" style="border-radius: 30px;">
                                    <span>Confirmar reservación</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 25px;
    width: 150px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.hr_head {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 42px;
    position: relative;
}

.hr_footer {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 282px;
    position: relative;
}

.shadow_cal {
    box-shadow: rgba(136, 165, 191, 0.48) 5px 6px 6px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.swal2-icon.swal2-success.swal2-icon-show {
    margin-top: 120px !important;
}

.img.swal2-image {
    position: absolute;
    left: 32% !important;
}
</style>
