<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';

// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
// import 'vue2-datepicker/locale/es';

import carousel from 'vue-owl-carousel'
import request from '../services/request'
import galeryOffice from '@/components/GaleryOfficeComponent.vue'
import moment from 'moment';
import ReserveComponent from '@/components/ReserveComponent.vue'

import loginModal from '@/components/ModalLoginComponent.vue'


export default {
    components: {
        carousel,
        galeryOffice,
        ReserveComponent,
        loginModal
    },
    data() {
        return {
            // es: es,
            zoom: 13,
            id: '',
            reseve_tye: 'init',
            day: '',
            time: '',
            baseFiles: this.$BaseUrlFiles,
            office:
            {
            },
            location: {
                lat: 19.432608,
                lng: -99.133209
            },
            date: '',
            timesInfo: [],
            timesUseds: [],
            filter: {
                date: '',
                time_start: '',
                time_end: '',
                participants: '',
            },
            favorites: []
        }
    },
    methods: {
        disabledRange: function (date) {
            let days = this.getDays();
            let nDay = new Date(date).getDay()
            return date <= moment().subtract(1, 'd') || days.includes(nDay);
        },
        disbaledDays() {
            let disabledDates = {
                to: moment().subtract(1, 'd').toDate(), // Disable all dates up to specific date
                days: this.getDays(), // Disable Saturday's and Sunday's              
            }
            return disabledDates;
        },
        showlogin() {
            console.log('pasoooo2');
            Event.$emit('event-show-login', true);
        },
        async showTime(data) {
            this.day = moment(data).format('YYYY-MM-DD');
            this.filter.date = moment(data).format('DD-MM-YYYY');
            let dateFormat = moment(data).format('YYYY-MM-DD');
            this.sendParms();
            let service = request;
            let _endpoint = `/booking/hours_bookings/${this.id}?date=${dateFormat}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.timesUseds = data.data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
            await this.getTimes(this.office.time_start, this.office.time_end);
            this.$modal.show('ModalHorarios');
        },
        closedModal() {
            this.$modal.hide('ModalHorarios');
        },
        sendParms() {
            Event.$emit('event-reload-params', this.filter);
        },
        showReserva() {
            this.$modal.show('ModalReservas');
        },
        getTimes(start = '09:00:00', end = '21:00:00', returnA = false) {
            let timesInBetween = [];
            let startH = parseInt(start.split(":")[0]);
            let startM = parseInt(start.split(":")[1]);
            let endH = parseInt(end.split(":")[0]);
            let endM = parseInt(end.split(":")[1]);

            if (startM == 30)
                startH++;
            for (let i = startH; i < endH; i++) {
                timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
                timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
            }
            timesInBetween.push(endH < 10 ? "0" + endH + ":00" : endH + ":00");
            if (endM == 30)
                timesInBetween.push(endH < 10 ? "0" + endH + ":30" : endH + ":30");
            if (returnA) {
                return timesInBetween;
            } else {
                this.timesInfo = timesInBetween;
            }
        },
        getDays() {
            let arrayDays = JSON.parse(this.office.days_available);
            let days = [];
            // 0 - Domingo
            // 6 - Sabado
            let daysWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            daysWeek.forEach(function (day) {
                if (!arrayDays.includes(day)) {
                    if (day == 'sunday') {
                        days.push(0);
                    }
                    if (day == 'monday') {
                        days.push(1);
                    }
                    if (day == 'tuesday') {
                        days.push(2);
                    }
                    if (day == 'wednesday') {
                        days.push(3);
                    }
                    if (day == 'thursday') {
                        days.push(4);
                    }
                    if (day == 'friday') {
                        days.push(5);
                    }
                    if (day == 'saturday') {
                        days.push(6);
                    }
                }
            });
            return days;
        },
        statusHorario(time) {
            let resul = false;
            let timesUseds = this.timesUseds;
            timesUseds.forEach(timesU => {
                let range = this.getTimes(timesU.time_start, timesU.time_end, true);
                if (range) {
                    if (range.includes(time)) {
                        resul = true;
                    }
                }
            });
            return resul;
        },
        async get_office() {
            let service = request;
            let _endpoint = `/booking/rooms/${this.id}`;
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    console.log(data.data);
                    this.office = data.data;
                    this.setHeaderTitle(`${this.office.name}`);
                    if (data.data.room_content) {
                        let pos = JSON.parse(data.data.room_content);
                        this.location.lat = pos.lat;
                        this.location.lng = pos.lng;
                    }
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        redirecPrin() {
            this.reseve_tye = 'init';
            this.redirectRes();
        },
        redirectRes() {
            if (this.authUser) {
                if (this.reseve_tye == 'init') {
                    this.$router.push({ name: 'reserve' });
                } else {
                    this.$router.push({ name: 'reserve', query: { time: this.time, day: this.day } });
                }
            } else {
                this.$modal.hide('ModalHorarios');
            console.log('pasoooo3');
                Event.$emit('event-show-login', true);
            }
        },
        redirectTime(time = false) {
            this.filter.time_start = time;
            this.sendParms();
            this.$modal.hide('ModalHorarios');
            // this.redirectRes();
        },
        async save_favorite(id) {
            try {
                let form = {
                    id: id
                };
                let _endpoint = '/favorite/save';
                let service = request;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.get_favorite();
                    if (data.type == 'deleted') {
                        this.errors = await this.successNotification('Éxito al eliminar de tus favoritos')
                    } else {
                        this.errors = await this.successNotification('Éxito al agregar a favoritos');
                    }
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async get_favorite() {
            try {
                let _endpoint = '/favorite/list';
                let service = request;
                let { data } = await service.get(_endpoint)
                if (data) {
                    let ids = data.map(x => x['room_id']);
                    this.favorites = ids;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        exist_favorite(id) {
            let result = this.favorites.includes(id);     // true
            console.log(result);
            return result;
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),

    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
    async mounted() {
        this.id = this.$route.params.id;
        if (this.authUser) {
            this.get_favorite();
        }
        if (this.id) {
            await this.get_office();
        }
    },
}
</script>
<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5 mb-5">
                <div class="d-block d-md-none d-lg-none d-xl-none"
                    style="position: fixed; bottom: 170px; right: 20px; z-index: 100;">
                    <div class="row">
                        <div class="col-12 text-center">
                            <button @click="showReserva()" class="btn btn-danger btn-lg rounded-r ">Reservar</button>
                        </div>
                    </div>
                </div>

                <div class="d-none d-md-block d-lg-block d-xl-block">
                    <div class="row">
                        <div class="col-md-8">
                            <h4 class="card-text mt-2 mb-4">
                                {{ office.address }}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right"
                        style="    position: absolute;z-index: 9;margin-top: 15px;margin-left: -10px;">

                        <ShareNetwork style="border-radius: 20px;" class="btn m-1 btn-danger" network="WhatsApp"
                            :url="`https://reservas-usuarios.sorianoariza.com//#/office/${office.id}`" :title="office.name">
                            <i class="fa-solid fa-x3 fa-share-nodes"></i>
                        </ShareNetwork>
                        <button v-if="authUser" style="border-radius: 20px;" class="btn m-1 btn-light"
                            @click="save_favorite(office.id)">
                            <i class="fa-solid fa-heart"
                                :class="exist_favorite(office.id) ? 'text-danger' : 'text-white'"></i>
                        </button>
                    </div>
                </div>

                <!-- Cards -->
                <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <div class="row">
                        <div class="col-12">
                            <galeryOffice :media="office.medias" />
                        </div>
                    </div>
                </div>

                <!-- CARROUCEL -->
                <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                    <div class="row">
                        <div class="col-12">
                            <carousel :autoplay="true" v-if="office.medias" :nav="true" :loop="true" :items="1">
                                <img v-for="(media, index_media) in office.medias" :key="index_media"
                                    :src="`${baseFiles}${media.media.file_path}${media.media.file_name}`">
                            </carousel>
                        </div>
                    </div>
                </div>

                <div class="row d-block  d-md-none  d-lg-none d-xl-none  mt-3">
                    <div class="col-12">
                        <h4 class="card-text ml-n2  text-muted col-12 col-md-8">
                            {{ office.address }}
                        </h4>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-6">
                        <h3>Amenidades con la que cuenta</h3>
                        <div class="row div_icons">
                            <div class="col-6 text-muted mt-3 mb-3" style="font-size: 20px;;">
                                <i class="text-danger fa-solid fa-users-gear"></i> {{ office.assistants }} asistentes
                            </div>
                            <div class="col-6 text-muted mt-3 mb-3"
                                v-for="(amenities, amenities_office) in office.amenities" :key="amenities_office"
                                style="font-size: 20px;;">
                                <i class="text-danger" :class="amenities.preference.icon"></i> {{ amenities.preference.name
                                }}
                            </div>
                            <div class="col-12 mt-2 mb-2">
                                <p class="text-muted text-justify">
                                    {{ office.description }}
                                </p>
                            </div>
                        </div>
                        <!-- <hr>
                    <h3 class="pb-4">Calendario de reservas</h3>
                    <div class="card shadow_cal" style="max-width: 340px; text-align: center; margin: 0 auto;">
                        <div class="card-body crd-res">

                            <date-picker v-model="date" :inline="true" @pick="showTime" :disabled-date="disabledRange">
                            </date-picker>
                         
                        </div>
                    </div> -->
                    </div>
                    <div class="col-md-6 parent d-none d-md-block d-lg-block d-xl-block">
                        <aside class="sidebar1">
                            <div class="component">
                                <div class="card br20 shaw20 mt-2 child">
                                    <div class="card-body">
                                        <ReserveComponent />
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>

                <div class="row mt-2" v-if="office.comment.length > 0">
                    <div class="col-12">
                        <hr>
                        <h3> Evaluaciones</h3>
                        <div class="row" v-for="(comment, index_comment) in office.comment" :key="index_comment">
                            <div class="col-12 mt-2" v-if="comment.type_comments == 1">
                                <div class="img-user">
                                    <img src="@/assets/img/user.png" width="40px">
                                </div>
                                <h5 style="margin-left: 70px; margin-top: 10px;">{{ comment.user.first_name }} {{
                                    comment.user.last_name }} - {{ comment.score }} <i class="fa-solid fa-star"></i> </h5>

                                <p class="mt-4 text-justify">
                                    {{ comment.comment }}
                                </p>
                            </div>

                        </div>


                        <!-- <button class="btn mt-4 btn-danger rounded-r ">Reservar</button> -->
                    </div>

                </div>

                <!-- Mapa -->
                <div class="row">
                    <div class="col-12">
                        <hr>
                        <h3 class="mb-4">Dónde vas a estar</h3>
                        <gmap-map class="full-width-div" :center="location" :zoom="zoom"
                            style="width: 100%; height: 400px;">
                            <gmap-marker :icon="require('@/assets/img/pin.png')" v-if="location.lng"
                                :position="location"></gmap-marker>
                        </gmap-map>
                    </div>

                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <h3>Condiciones de tu reserva</h3>
                        <hr>
                        <p style="font-size: 15px;" class="mt-2 mb-1 text-justify">Lorem ipsum dolor sit amet, consectetuer
                            adipiscing
                            elit,
                            sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
                            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
                            nisl ut aliquip ex ea commodo consequat.
                            Duis autem vel eum iriure dolor
                        </p>
                        <!-- <button class="btn mt-4 btn-danger rounded-r ">Reservar</button> -->
                    </div>

                </div>
            </div>

            <modal name="ModalReservas" :width="'95%'" :maxWidth="400" :adaptive="true" :shiftY="0.1">
                <div class="p-4">
                    <ReserveComponent />
                </div>
            </modal>

            <!-- Horarios -->
            <!-- Filtro -->
            <modal name="ModalHorarios" :width="'95%'" :maxWidth="400" :adaptive="true" :shiftY="0.1">
                <div class="row p-4">
                    <div class="col-12">
                        <h4>Horarios disponibles</h4>
                        <hr>
                    </div>
                    <div class="col-12">
                        <table class="table table-sm">
                            <thead class="thead-dark">
                                <tr>
                                    <th class="text-center">Horario</th>
                                    <th class="text-center">Estatus</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(time, index_time) in timesInfo" :key="index_time">
                                    <td class="text-center">
                                        {{ time }} Hrs.
                                    </td>
                                    <td class="text-center">
                                        <h4 class="mb-0">
                                            <span v-if="!statusHorario(time)" @click="redirectTime(time)"
                                                style="width: 140px; padding: 6px;"
                                                :class="statusHorario(time) ? 'badge-danger' : 'badge-success'"
                                                class="badge ">
                                                <i class=" mr-2"
                                                    :class="statusHorario(time) ? 'fa-solid fa-calendar-minus' : 'fa-regular fa-calendar-check'"></i>
                                                <strong v-if="statusHorario(time)">Ocupado</strong>
                                                <strong v-else>Libre</strong>
                                            </span>
                                            <span v-else style="width: 140px; padding: 6px;"
                                                :class="statusHorario(time) ? 'badge-danger' : 'badge-success'"
                                                class="badge disabled">
                                                <i class=" mr-2"
                                                    :class="statusHorario(time) ? 'fa-solid fa-calendar-minus' : 'fa-regular fa-calendar-check'"></i>
                                                <strong v-if="statusHorario(time)">Ocupado</strong>
                                                <strong v-else>Libre</strong>
                                            </span>
                                        </h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="col-12 text-right">
                        <hr>
                        <button @click="closedModal()" class="btn btn-danger">Cerrar</button>
                    </div>
                </div>
            </modal>
            <!-- Modal Horarios -->
            <loginModal/>
        </div>
    </div>
</template>
<style scoped>
@media (max-width: 360px) {
    .crd-res {
        padding: 0px;
    }
}

@media (max-width:767px) {
    .mb-5 {
        margin-bottom: 7rem !important;
    }
}

.btn-like {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
}

.div_star {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.rounded-r {
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 150px;
}

.rounded-lg {
    border-radius: 20px !important;
}

.hr_head {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 42px;
    position: relative;
}

.hr_footer {
    margin: 0 auto;
    width: 96%;
    border-top: 2px solid #d4d6d5;
    top: 282px;
    position: relative;
}

.shadow_cal {
    box-shadow: rgba(136, 165, 191, 0.48) 5px 6px 6px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.img-user {
    float: left;
    background: #e1e1e1;
    border: 1px solid #000;
    border-radius: 40px;
}

.sidebar1 {
    --offset: 5px;
    position: sticky;
    top: var(--offset);
}

.component {
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.component .content {
    max-height: 500px;
    overflow-y: auto;
}

.sidebar1 .component {
    max-height: calc(100vh - 5px);
}</style>