<script>
import { mapActions, mapMutations } from 'vuex';
import request from '../services/request'
import ModalMapComponent from '@/components/ModalMapComponent.vue';

export default {
    components: {
        ModalMapComponent
    },
    data() {
        return {
            likes: [],
            baseFiles: this.$BaseUrlFiles,
            baseUrl: window.location.origin
        }
    },
    methods: {
        showMap(office) {
            console.log(office);
            Event.$emit('event-map-office', office);
        },
        async get_favorite() {
            try {
                let _endpoint = '/favorite/list';
                let service = request;
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.likes = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async delete_favorite(id) {
            try {
                let form = {
                    id: id
                };
                let _endpoint = '/favorite/save';
                let service = request;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.get_favorite();
                    if (data.type == 'deleted') {
                        this.errors = await this.successNotification('Éxito al eliminar de tus favoritos')
                    } else {
                        this.errors = await this.successNotification('Éxito al agregar a favoritos');
                    }
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        redirectOffice(id) {
            let search = this.search;
            localStorage.setItem('filter_data', JSON.stringify(search));
            this.$router.push({ path: '/office/' + id })
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
    },
    mounted() {
        this.get_favorite();
        this.setHeaderTitle(`Favoritos`);
    }
}
</script>
<template>
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 slider">
                <div class="row mt-5 pb-4" v-if="likes.length > 0">
                    <div v-for="(like, index_like) in likes" :key="index_like" class="col-md-6 col-12">
                        <div class="card br20 m-2 shaw20">
                            <img v-if="like.room.medias.length > 0"
                                :src="`${baseFiles}${like.room.medias[0].media.file_path}${like.room.medias[0].media.file_name}`"
                                class="rounded-top card-img-top" alt="photo">
                            <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top" alt="photo">

                            <div class="card-body card-info-room">
                                <!-- <button class="btn btn-link btn-options">
                                <i class="fas fa-ellipsis-h fa-lg"></i>
                            </button> -->
                                <div class="btn-group btn-options" role="group">
                                    <button type="button" class="btn btn-light" data-toggle="dropdown"
                                        aria-expanded="false">
                                        <i class="fas fa-ellipsis-h fa-lg"></i>
                                    </button>
                                    <div class="dropdown-menu br20" style="padding: 2px;">
                                        <a class="dropdown-item" href="#" @click="delete_favorite(like.room.id)">
                                            Eliminar
                                            <i class="fas fa-trash float-right"></i>
                                        </a>
                                        <ShareNetwork class="dropdown-item" network="WhatsApp"
                                            :url="`${baseUrl}/#/office/${like.room.id}`"
                                            :title="like.room.name">
                                            Compartir
                                            <i class="fa-solid fa-share-nodes float-right"></i>
                                        </ShareNetwork>
                                    </div>
                                </div>
                                <h4 @click="redirectOffice(like.room.id)" role="button" class="card-title tit" style="font-weight: 700;"> <i
                                        class="fa-lg text-danger fa-solid fa-heart"></i> {{ like.room.name }}</h4>
                                <p class="card-text card_address col-10 mt-n3 mr-5">
                                    <a @click="showMap(like.room)" class="text-muted">
                                        {{ like.room.address }}
                                    </a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row mt-5 justify-content-center">
                    <div class="col-7 text-center">
                        <div class="card">
                            <div class="card-body p-5">
                                <i class="fa-solid fa-house-medical-flag fa-5x text-danger"></i>
                                <H3 class="mt-3">Sin salas guardadas como favoritos</H3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ModalMapComponent />
        </div>
    </div>
</template>
<style>
.tit {
    font-size: 20px;
    font-weight: 600;
}

.btn-options {
    position: absolute !important;
    right: 15px;
}

.dropdown-menu.show {
    left: -110px !important;
}
</style>