export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
        },
        error({ commit }, error = {}) {
            try{
                console.log(error);
                console.log(error.response.status);
                console.log(error.response.data.message);
                // Validación API
                if (error.response.status == 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    location.reload();
                    // this.$router.push('home')
                    // Validación Campos
                } else if (error.response.status == 422) {
                    return Promise.resolve(error.response.data.errors);
                } else if(error.response.status == 404){
                    if( error.response.data.message){
                        commit('error', error.response.data.message);                
                    }
                } else {
                    commit('error', 'Ups. Parece que algo salio mal...');
                    return Promise.resolve({});
                }
                // commit('error', message);
            }catch(error){
                console.log('promise', error);
            }
        },
        clear({ commit }) {
            try{
                commit('clear');
            }catch(error){
                console.log('error clear', error);
            }
        }
    },
    mutations: {
        success(state, message) {
            try{
                state.type = 'success';
                state.message = message;
            }catch(error){
                console.log('succes', error);
            }
        },
        error(state, message) {
            console.log(message);
            console.log(state);
            state.type = 'error';
            state.message = message;
        },
        clear(state) {
            state.type = null;
            state.message = null;
        }
    }
}
