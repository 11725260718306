<script>

import Catalogue from '@/services/catalogue';
import { mapGetters, mapActions } from 'vuex';
import ModalMapComponent from '@/components/ModalMapComponent.vue';
import request from '../services/request'

export default {
    components: {
        ModalMapComponent
    },
    data() {
        return {
            catalogue: new Catalogue(),
            favorites: [],
            baseFiles: this.$BaseUrlFiles,
        }
    },
    methods: {
        exist_favorite(id) {
            let result = this.favorites.includes(id);     // true
            console.log(result);
            return result;
        },
        async save_favorite(id) {
            try {
                let form = {
                    id: id
                };
                let _endpoint = '/favorite/save';
                let service = request;

                let { data } = await service.post(_endpoint, form)
                if (data) {
                    this.get_favorite();
                    if (data.type == 'deleted') {
                        this.errors = await this.successNotification('Éxito al eliminar de tus favoritos')
                    } else {
                        this.errors = await this.successNotification('Éxito al agregar a favoritos');
                    }
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        async get_favorite() {
            try {
                let _endpoint = '/favorite/list';
                let service = request;
                let { data } = await service.get(_endpoint)
                if (data) {
                    let ids = data.map(x => x['room_id']);
                    this.favorites = ids;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showMap(office) {
            console.log(office);
            Event.$emit('event-map-office', office);
        },
        redirectOffice(id) {
            let search = this.search;
            localStorage.setItem('filter_data', JSON.stringify(search));
            this.$router.push({ path: '/office/' + id })
        },
        getDays(days) {
            let arrayDays = JSON.parse(days);
            let daysSpanish = [];
            arrayDays.forEach(function (day) {
                if (day == 'monday') {
                    daysSpanish[0] = 'Lun';
                }
                if (day == 'tuesday') {
                    daysSpanish[1] = 'Mar';
                }
                if (day == 'wednesday') {
                    daysSpanish[2] = 'Mie';
                }
                if (day == 'thursday') {
                    daysSpanish[3] = 'Jue';
                }
                if (day == 'friday') {
                    daysSpanish[4] = 'Vie';
                }
                if (day == 'saturday') {
                    daysSpanish[5] = 'Sáb';
                }
                if (day == 'sunday') {
                    daysSpanish[6] = 'Dom';
                }
            });
            let text = '';
            let nDays = daysSpanish.length;
            nDays--;
            daysSpanish.forEach(function (day, key) {
                if (day) {
                    text += day;
                    if (nDays != key) {
                        text += ', ';
                    }
                }
            });
            return text;
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),

    },
    async mounted() {
        await this.catalogue.get_random_rooms();
        if (this.authUser) {
            await this.get_favorite();
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
        })
    },
}
</script>
<template>
    <div class="row mt-4 justify-content-center pb-4" v-if="catalogue.random_rooms.data.length">
        <div v-for="(like, index_like) in catalogue.random_rooms.data" :key="index_like" class="col-md-6 col-lg-4 col-12">
            <div class="card br20 m-2 shaw20">
                <i v-if="authUser" class="fa-lg fa-solid fa-heart btn-like" @click="save_favorite(like.id)"
                    :class="exist_favorite(like.id) ? 'text-danger' : 'text-muted'"></i>
                <img v-if="like.medias.length > 0"
                    :src="`${baseFiles}${like.medias[0].media.file_path}${like.medias[0].media.file_name}`"
                    class="card-img-top rounded-top" alt="photo">
                <img v-else src="@/assets/img/office/1.jpg" class="card-img-top rounded-top" alt="photo">
                <div class="card-body card-info-room">
                    <div class="div_star">
                        <i class="fa-solid fa-star" :class="like.average_score > 0 ? 'text-warning' : 'text-muted'"></i>
                        <span class="ml-2" v-if="like.average_score">{{ parseFloat(like.average_score).toFixed(2) }}</span>
                        <span class="ml-2" v-else>0</span>
                    </div>
                    <h4 role="button" @click="redirectOffice(like.id)" class="card-title tit">{{ like.name }}</h4>
                    <p class="card-text ml-n2 card_address mt-4 col-10" style="font-size: 12px;">
                        <a @click="showMap(like)" class="text-muted">
                            <i class="fa-solid fa-sm fa-location-pin text-danger mr-1"></i>
                            {{ like.address }}
                        </a>
                    </p>
                    <div class="row mt-n3">
                        <div class="col-2 col-lg-2 text-sm mt-1 mb-1">
                            <div :aria-label="like.assistants + ' asistentes'"
                                class="hint--bottom p-0 btn btn-link text-dark">
                                <i class="fa-solid fa-users-gear fa-lg"></i>
                            </div>
                        </div>
                        <div class="col-2 col-lg-2 text-sm mt-1 mb-1" v-for="(amenities, amenities_like) in like.amenities"
                            :key="amenities_like" v-show="amenities_like <= 4">
                            <div :aria-label="amenities.preference.name + ' asistentes'"
                                class="hint--bottom p-0 btn btn-block btn-link text-dark">
                                <i :class="amenities.preference.icon" class="fa-lg"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h5 class="text-success">Disponible</h5>
                            <p style="font-size: 13px;" class="mt-n1 mb-1">Días disponibles:</p>
                            <p style="font-size: 13px;" class="mb-2 text-muted">{{ getDays(like.days_available)
                            }}</p>
                            <p style="font-size: 13px;" class="mt-n1 mb-1">Horarios:</p>
                            <p style="font-size: 13px;" class="mt-1 mb-0 text-muted"> {{
                                like.time_start.substring(0, 5) }} Hrs - {{ like.time_end.substring(0, 5) }} Hrs
                            </p>
                            <button @click="redirectOffice(like.id)"
                                class="btn shadow_btn mt-3 btn-danger btn-reseve br20 pl-4 pr-4">Ver más</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
        <ModalMapComponent />
    </div>
</template>
