import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// Template
import ThemePage from '../pages/layouts/ThemePage'

// Inicio
import Home from '../pages/HomePage'
import List from '../pages/ListPage'
import Favorites from '../pages/FavoritesPage'
import Profile from '../pages/ProfilePage'
import Bookings from '../pages/BookingsPage'
import Login from '../pages/auth/LoginPage'
import Office from '../pages/OfficePage'
import Reserve from '../pages/ReservePage'
import EmailReset from '../pages/auth/EmailResetPage'
import ReviewsPageVue from '@/pages/ReviewsPage.vue'
import ReportPage from '@/pages/ReportPage.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'page',
		component: ThemePage,
		children: [
			{ path: '/', name: 'home', component: Home },
			{ path: '/login', name: 'login', component: Login },
			{ path: '/list', name: 'list', component: List },
			{ path: '/office/:id', name: 'office', component: Office },
			{ path: '/favorites', name: 'favorites', component: Favorites },
			{ path: '/profile', name: 'profile', component: Profile },
			{ path: '/bookings', name: 'bookings', component: Bookings },
			{ path: '/reserve/:id', name: 'reserve', component: Reserve },
			{ path: '/reset', name: 'reset', component: EmailReset },
			{ path: '/reviews/:id', name: 'reviews', component: ReviewsPageVue },
			{ path: '/report/:id', name: 'report', component: ReportPage },
		]
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {	

	// Cargador de pagina
	if (to.name != from.name) {
		store.state.status.routeChange = true;
	}

	// Accesos publicos
	const publicPages = ['home', 'list', 'office', 'login', 'reset'];
	const authRequired = !publicPages.includes(to.name);

	// Verificamos si esta logueado
	const loggedIn = JSON.parse(localStorage.getItem('user'));
		
	// Redirige a home si se loguea
	if (loggedIn && !authRequired) {
		if(to.name == 'login' || from.name == 'login'){					
			return next('profile');
		}
	}

	// Redirige a login si no esta logueado
	if (authRequired && !loggedIn) {	
		if(to.name == 'login' || from.name == 'login'){			
			setTimeout(() => {
				store.state.status.routeChange = false;
			}, 1000);
			return next('login');			
		}else{
			return next('login');			
		}
	}

	next();
});

router.afterEach((to, from) => {
	// Cargador de pagina
	if (to.name != from.name) {
		setTimeout(() => {
			store.state.status.routeChange = false;
		}, 1000);
	}

})

export default router
