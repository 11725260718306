<script>
import carousel from 'vue-owl-carousel'
import Catalogue from '@/services/catalogue';

export default {
    components: {
        carousel,
    },
    data() {
        return {
            catalogue: new Catalogue(),
        }
    },
    async mounted() {
        await this.catalogue.get_amenities();
    }
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <carousel :responsive="{0:{items:1}, 425:{items:2},600:{items:2},768:{items:4},1200:{items:5}}" :autoplay="true" v-if="!catalogue.amenities_load" :nav="false" :loop="true">
                <div class="m-2 card text-center br20" v-for="(icon, index_icon) in catalogue.amenities" :key="index_icon">
                    <div class="card-body p-1 p">
                        <div class="icon-flot">
                            <i :class="icon.icon" class="fa-3x"></i>
                        </div>
                        <p class="mt-3">{{ icon.name }}</p>
                    </div>
                       
                </div>
            </carousel>
        </div>
    </div>
</template>
<style scoped>
.card{
    border: 3px solid #fff;
    background-color: #5a5a5a;
    color: #fff;
}
.icon-flot{
    padding: 20px;
    background-color: #dc3545;
    margin-top: -20px;
    max-width: 120px;
    margin: -15px auto 10px;
    border-radius: 20px;
}
</style>