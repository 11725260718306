export const setRouteChange = (state, payload) => {
    state.routeChange = payload;
}

export const setHeaderTitle = (state, payload) => {
    if (payload && payload!='') {
        state.header_title = payload;
    } else {
        state.header_title = null;
    }
}