<script>
import { mapActions, mapMutations } from 'vuex';
import decrypt from "@/services/decrypt";

export default {
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            valid_mail: false,
            errors: {},
            submitted: false
        }
    },
    methods: {
        async triggerLogin() {
            try {
                let login = await this.loginAction(this.form)
                if (login) {
                    console.log(login);
                    this.$nextTick(() => {
                        location.reload()
                    })
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async checkQueryToken() {
            let vm = this;
            if (vm.$route.query && Object.keys(vm.$route.query).length > 0) {
                let _params = {};
                Object.keys(vm.$route.query).forEach((key) => {
                    try {
                        console.log(key + ": ", vm.$route.query[key]);
                        localStorage.setItem(`cs_${key}`, vm.$route.query[key])
                        _params[key] = decrypt.AESDecrypt(vm.$route.query[key]);
                        console.log(key + ": ", _params[key]);
                    } catch (error) {
                        console.log("error: ", error);
                    }
                });

                if (_params && Object.keys(_params).length > 0) {
                    await vm
                        .authLoginWithToken(_params)
                        .then((data_login) => {
                            console.log(data_login);
                            location.reload()
                            // vm.$snotify.success("¡Bienvenido!");
                            // vm.$router.push({ name: "app-home" });
                        })
                        .catch((err) => {
                            try {
                                // vm.$snotify.error(err.response.data.message);
                                console.log(err.response.data.message);
                            } catch (err) {
                                console.log("err", err);
                                // vm.$snotify.error("Ups. Parece que algo salio mal...");
                            }
                        });
                }

            }


        },
        ...mapActions({
            loginAction: 'auth/login',
            errorNotification: 'alert/error',
            authLoginWithToken: "auth/loginWithToken",
        }),
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
    },
    async mounted() {
        await this.checkQueryToken();
        this.setHeaderTitle(`Iniciar sesión`);
    }
}
</script>
<template>
    <div class="row m-0">
        <div class="lockscreen-wrapper">
            <!-- <div class="col-12 slider">
                <div class="col-12 mt-5 text-center">
                    <img src="@/assets/img/logo_rojo.png" classs="img-fluid mt-5" alt="elektra" style="max-width: 300px;">
                </div>
            </div> -->
            <div class="col-12 text-center mt-5">
                <h1 class="text-danger">Bienvenido</h1>
                <p class="text-muted">Inicie sesión en su cuenta completando este formulario</p>
            </div>
            <form @submit.prevent="triggerLogin" class="mt-3">
                <div class="col-12 mt-4">
                    <input type="text" v-model="form.email" class="form-control" required
                        placeholder="Correo electrónico o Usuario">
                    <div v-if="errors && errors.email" class="text-danger mt-1">{{ errors.email[0] }}</div>
                </div>
                <div class="col-12 mt-3">
                    <input type="password" v-model="form.password" class="form-control" required placeholder="Contraseña">
                    <div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
                </div>
                <div class="col-12 mt-4">
                    <div class="row">
                        <div class="col-8 text-left">
                            <router-link to="/reset" class="btn btn-link text-danger">Recuperar contraseña</router-link>
                        </div>
                        <div class="col-4 text-right">
                            <button class="btn btn-block btn-danger br20 shaw20">Ingresar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<style>
.tit {
    font-size: 20px;
    font-weight: 600;
}

.btn-options {
    position: absolute !important;
    right: 15px;
}

.dropdown-menu.show {
    left: -110px !important;
}

@media (max-width: 450px) {
    .lockscreen-wrapper {
        width: 100%;
    }
}
</style>