<script>
export default {
    data() {
        return {
            zoom: 13,
            office: {},
            location: {
                lat: 19.432608,
                lng: -99.133209
            },
        }
    },
    methods:{
        closed(){
            this.$modal.hide('ModalMap');
        }
    },  
    created() {
        Event.$on("event-map-office", (office) => {
            let location = JSON.parse(office.room_content);
            this.location.lat = location.lat;
            this.location.lng = location.lng;
            this.office = office;
            this.$modal.show('ModalMap');

        });
    },
}
</script>
<template>
    <modal name="ModalMap" :width="'95%'" :maxWidth="700" :adaptive="true" :shiftY="0.1">
        <div style="background-color: #343a40;">
            <h5 class="p-3 text-white">
                {{ office.address }}
                <button class="btn btn-sm btn-danger btn-closed-modal float-right br20" @click="closed">
                    <i class="fa-solid fa-x"></i>
                </button>
            </h5>
        </div>
        <div class="p-3">                
            <gmap-map class="full-width-div" :center="location" :zoom="zoom" style="width: 100%; height: 400px;">
                <gmap-marker :icon="require('@/assets/img/pin.png')" v-if="location.lng" :position="location"></gmap-marker>
            </gmap-map>
        </div>
    </modal>
</template>
<style>
.btn-closed-modal{
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>