import * as auth from '../../services/auth'
import request from '../../services/request'

export const register = async (payload) => {
	try {
		let { data } = await auth.register(payload)
		if (data) {
			return Promise.resolve(data)
		} else {
			return Promise.reject('error')
		}
	} catch (err) {
		return Promise.reject(err)
	}
}

export const login = async ({ commit }, payload) => {
	try {
		let { data } = await auth.login(payload)
		if (data) {
			if (data.access_token) {
				commit('setUser', data.user);
				commit('setToken', data.access_token);
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		} else {
			return Promise.reject(data)
		}
	} catch (err) {
		return Promise.reject(err)
	}
}

export const loginWithToken = async ({ commit }, payload) => {
	try {
		commit('setUser', null);
		commit('setToken', null);
		let endpoint = `/auth/loginWithToken`;
		let { data } = await request.post(endpoint, payload);
		console.log(data);
		if (data) {
			if (data.access_token) {
				commit('setUser', data.user);
				commit('setToken', data.access_token);
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		} else {
			return Promise.reject(data)
		}
	} catch (err) {
		return Promise.reject(err)
	}
}


export const resetPass = async (payload) => {
	try {
		let { data } = await request.post('/auth/reset-password', payload)
		return Promise.resolve(data)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const changepass = async (payload) => {
	try {
		let { data } = await request.post('/auth/change-password', payload)
		return Promise.resolve(data)
	} catch (err) {
		return Promise.reject(err)
	}
}



export const logout = async ({ commit }) => {
	try {
		commit('setUser', null);
		commit('setToken', null);
		return Promise.resolve(true)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const getUser = async () => {
	try {
		let { data } = await auth.getUser()
		console.log(data)
		// commit('setUser', data)
		return Promise.resolve(true)
	} catch (err) {
		// commit('setUser', null)
		// commit('setToken', null)
		return Promise.reject(false)
	}
}

export const updateUser = async (payload) => {
	try {
		let { data } = await auth.updateUser(payload)
		console.log(data)
		// commit('setUser', data)
		return Promise.resolve(true)
	} catch (err) {
		// commit('setUser', null)
		// commit('setToken', null)
		return Promise.reject(false)
	}
}

