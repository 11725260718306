import request from './request'

export default class Catalogos {

    constructor() {
        this.type_rooms_load = true;
        this.type_rooms = [];
        this.territories_load = true;
        this.territories = [];
        this.regions_load = true;
        this.regions = [];
        this.num_assistants_load = true;
        this.num_assistants = [];
        this.min_max_assistants_load = true;
        this.min_max_assistants = [];
        this.min_max_hours_load = true;
        this.min_max_hours = [];
        this.random_rooms_load = true;
        this.random_rooms = [];
        this.amenities_load = true;
        this.amenities = [];
    }

    async get_type_rooms() {
        this.type_rooms_load = true;
        let _endpoint = '/catalogue/type_rooms';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_rooms = data;
                this.type_rooms_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }


    async get_territories(search = '') {
        this.territories_load = true;
        let _endpoint = '/catalogue/territories?search=' + search;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.territories = data;
                this.territories_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_regions(search = '', id = '') {
        this.regions_load = true;
        let _endpoint = '/catalogue/regions?search=' + search + '&territory_id=' + id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.regions = data;
                this.regions_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_num_assistants() {
        this.num_assistants_load = true;
        let _endpoint = '/catalogue/assistants';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.num_assistants = data;
                this.num_assistants_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_min_max_assistants() {
        this.min_max_assistants_load = true;
        let _endpoint = '/catalogue/assistants';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.min_max_assistants = data;
                this.min_max_assistants_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_min_max_hours() {
        this.min_max_hours_load = true;
        let _endpoint = 'catalogue/times';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.min_max_hours = data;
                this.min_max_hours_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_random_rooms() {
        this.random_rooms_load = true;
        let _endpoint = 'catalogue/random';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.random_rooms = data;
                this.random_rooms_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_amenities() {
        this.amenities_load = true;
        let _endpoint = 'catalogue/amenities';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.amenities = data;
                this.amenities_load = false;
            }
        } catch (error) {
            console.log(error);
        }
    }

}